import { history } from 'umi';
import { message } from 'iglooform';
import { cloneDeep } from 'lodash';
import type { RequestConfig } from 'umi';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { getJWT, removeJWT } from '@/utils/jwt';

/**
 * Decamelize Request Params Keys
 * @param url
 * @param options
 */
const decamelizeParams = (url: string, options: any) => {
  const { params, data, headers } = options;
  if (url.includes(BASE_URL))
    return {
      url,
      options: {
        ...options,
        timeout: 5000,
        headers: {
          'X-Axinan-Authorization': getJWT(),
          ...headers,
        },
        params: decamelizeKeys(params),
        data: decamelizeKeys(data),
      },
    };
  return { url, options };
};

/**
 * Camelize Response Data Keys
 * @param response
 */
const camelizeData = async (response: any, options: any) => {
  const { status } = response;
  const { method } = options;

  const json = await response.json();

  const body = camelizeKeys(json, (key: string, convert) => {
    return /^[A-Z].+$/.test(key) ? key : convert(key);
  });

  switch (status) {
    case 200:
      return cloneDeep(body);
    default:
      return {
        method,
        responseStatus: status,
        ...json,
      };
  }
};

export const request: RequestConfig | { headers: Headers } = {
  prefix: BASE_URL,
  timeout: 5000,
  errorConfig: {
    adaptor,
  },
  requestInterceptors: [decamelizeParams],
  responseInterceptors: [camelizeData],
};

function adaptor(resData: any) {
  const { responseStatus } = resData;
  if (responseStatus) {
    if (responseStatus === 401) {
      const email = localStorage.getItem(USER_EMAIL_ADDRESS);
      if (!email) {
        history.push('/errors');
      }
      message.error('Please login');
      removeJWT();
      history.push(`/portal/login`);
    }
    if (responseStatus === 404) {
      history.push('/404');
    }
    if (responseStatus <= 500 && responseStatus >= 400) {
      if (
        resData.message === "Insured email can't be same with secondary email"
      ) {
        message.error(
          'Since you are activating the policy for someone else, the email address of the insured should be different from that of the applicant, please don’t input duplicated email addresses.',
        );
      } else {
        message.error(resData.message);
      }
    }
  }
  return resData;
}
