import { request } from 'umi';

export function getPolicyDetail({ quoteDisplayId }: Record<string, string>) {
  return request(`/public/activation/quote/${quoteDisplayId}`, {
    method: 'GET',
  });
}

export function validateVoucherCode({
  quoteDisplayId,
  data,
}: Record<any, any>) {
  return request(`/public/activation/quote/${quoteDisplayId}`, {
    method: 'POST',
    data,
  });
}

export const validateActivePolicy = (data: any) =>
  request('/public/activation/validate/email-mobile', {
    method: 'POST',
    data,
  });

export const activateQuote = (data: any) =>
  request('/public/activation/quote/activate', {
    method: 'POST',
    data,
  });
