// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/jenkins/agent/workspace/dev-auto-build-web-cp-rewire/go/src/github.com/axinan-com/goserver/rewire-cp/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/401",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__401' */'@/pages/401.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/403",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403' */'@/pages/403.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/activation/activationForm",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activation__activationForm__index' */'@/pages/activation/activationForm/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/activation/confirmation",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activation__confirmation__index' */'@/pages/activation/confirmation/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/activation",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activation__index' */'@/pages/activation/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/errors",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__errors' */'@/pages/errors.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/portal/login",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portal__login__index' */'@/pages/portal/login/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/portal/policy/detail",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portal__policy__detail' */'@/pages/portal/policy/detail.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/portal/policy",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__portal__policy__index' */'@/pages/portal/policy/index.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
