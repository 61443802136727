import { message } from 'iglooform';
import { history } from 'umi';
import type { Effect, Reducer } from 'umi';
import type { PolicyActivationDetails } from '@/interface/activation';

import {
  getPolicyDetail,
  validateVoucherCode,
  validateActivePolicy,
} from '@/services/activation';

interface InsuredDetails {
  fullName: string;
  birthdate: string;
  addressLine1: string;
  addressLine2: string;
  email: string;
  mobile: string;
}

interface BeneficiaryDetails {
  fullName: string;
  relation: string;
  birthdate: string;
  email: string;
  mobile: string;
}

export interface ActivationState {
  forms: {
    insuredDetails: InsuredDetails;
    beneficiaryDetails: BeneficiaryDetails;
  };
  policyActivationDetails: PolicyActivationDetails;
  quoteDisplayId: string | undefined;
  policyDisplayId: string | undefined;
  isQuoteValid: boolean;
  secondaryEmail: string | undefined;
  protectFamily: string;
}

interface ActivationModel {
  namespace: 'activation';
  state: ActivationState;
  reducers: {
    setQuoteId: Reducer<ActivationState>;
    setPolicyDisplayId: Reducer<ActivationState>;
    setSecondaryEmail: Reducer<ActivationState>;
    setProtectFamily: Reducer<ActivationState>;
    setDetails: Reducer<ActivationState>;
    setPolicyActivationDetails: Reducer<PolicyActivationDetails>;
    setIsQuoteValid: Reducer<ActivationState>;
  };
  effects: {
    getPolicyDetail: Effect;
    validateActivePolicy: Effect;
    validateVoucherCode: Effect;
  };
}

interface ValidateVoucherCodeResponse {
  continueProcessing: boolean;
}

interface ValidateActivePolicyResponse {
  continueProcessing: boolean;
  email: boolean | null;
  mobile: boolean | null;
}

const Activation: ActivationModel = {
  namespace: 'activation',
  state: {
    forms: {
      insuredDetails: {
        fullName: '',
        birthdate: '',
        addressLine1: '',
        addressLine2: '',
        email: '',
        mobile: '',
      },
      beneficiaryDetails: {
        fullName: '',
        relation: '',
        birthdate: '',
        email: '',
        mobile: '',
      },
    },
    policyActivationDetails: {
      activated: null,
      email: '',
      premium: '',
      premiumCurrency: '',
      state: undefined,
      productName: '',
      planName: '',
      coverage: {
        add: '',
        mr: '',
        uma: '',
        fca: '',
        be: '',
      },
    },
    quoteDisplayId: undefined,
    policyDisplayId: undefined,
    isQuoteValid: false,
    secondaryEmail: undefined,
    protectFamily: '',
  },
  reducers: {
    setQuoteId(state: any, { payload }: any) {
      return { ...state, quoteDisplayId: payload.quoteDisplayId };
    },
    setPolicyDisplayId(state: any, { payload }: any) {
      return { ...state, policyDisplayId: payload.policyDisplayId };
    },
    setSecondaryEmail(state: any, { payload }: any) {
      return { ...state, secondaryEmail: payload.secondaryEmail };
    },
    setProtectFamily(state: any, { payload }: any) {
      return { ...state, protectFamily: payload.protectFamily };
    },
    setDetails(state: any, { payload }: any) {
      const { forms } = payload;
      return { ...state, forms };
    },
    setPolicyActivationDetails(state: any, { payload }: any) {
      return { ...state, policyActivationDetails: payload };
    },
    setIsQuoteValid(state: any, { payload }: any) {
      return { ...state, isQuoteValid: payload.isQuoteValid };
    },
  },
  effects: {
    *getPolicyDetail(action, { call, put }: any) {
      const { quoteDisplayId } = action.payload;

      const resp: PolicyActivationDetails | any = yield call(getPolicyDetail, {
        quoteDisplayId,
      });

      if (!resp?.error) {
        yield put({
          type: 'setPolicyActivationDetails',
          payload: {
            ...resp,
          },
        });
      }
      if (resp?.code) {
        history.push('/404');
      }
    },

    *validateVoucherCode(action, { call }: any) {
      try {
        const resp: ValidateVoucherCodeResponse | any = yield call(
          validateVoucherCode,
          {
            ...action.payload,
          },
        );
        if (!resp?.error) {
          return resp;
        }
      } catch (e: any) {
        message.error(e.message);
      }
      return null;
    },

    *validateActivePolicy(action, { call }: any) {
      try {
        const resp: ValidateActivePolicyResponse | any = yield call(
          validateActivePolicy,
          {
            ...action.payload,
          },
        );
        if (!resp?.error) {
          return resp;
        }
      } catch (e: any) {
        message.error(e.message);
      }
      return null;
    },
  },
};

export default Activation;
