import jwt_decode from 'jwt-decode';

type JWT = unknown;

const key = TOKEN_NAME;

const getJWT = (): string | undefined => {
  const jwtToken = sessionStorage.getItem(key);
  if (jwtToken) return jwtToken;
  return undefined;
};

const setJWT = (token: string | undefined): string | undefined => {
  if (token) sessionStorage.setItem(key, token);
  return token;
};

const removeJWT = (): void => {
  sessionStorage.removeItem(key);
};

const parseJWT = (token: string): JWT | null => {
  return jwt_decode(token);
};

const setLocalStorage = (data: Record<string, string>) => {
  for (const [name, value] of Object.entries(data)) {
    localStorage.setItem(`${PLATFORM}_${ENV}_${name}`, value);
  }
};

const getLocalStorage = (name: string): undefined | string => {
  const value = localStorage.getItem(`${PLATFORM}_${ENV}_${name}`);
  if (value) return value;
  return undefined;
};

export {
  getJWT,
  setJWT,
  removeJWT,
  parseJWT,
  setLocalStorage,
  getLocalStorage,
};
